.App-header3{
    background-image: "https://nte-na3-resources.s3-us-west-1.amazonaws.com/ROG.jpg";
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: rgb(255, 255, 255);
  }

.App-logo3 {
    height: 40vmin;
    pointer-events: none;
  }