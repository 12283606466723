.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clorpage-text{
  color: white;
  text-align: left;
}
.clorpage-text{
    background-color: rgb(55, 0, 255);
    border-style: solid;
    border-color: slategray;
    animation-name: example2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    position: relative;
    animation-direction: alternate-reverse;
    animation-timing-function: ease;
  }
@keyframes example2 {
  0%   {background-color:red; left:0px; top:0px;}
  25%  {background-color:yellow; left:200px; top:0px;}
  50%  {background-color:rgb(0, 255, 255); left:200px; top:200px;}
  75%  {background-color:green; left:0px; top:200px;}
  100% {background-color:purple; left:0px; top:0px;}
}


.rainbow-text {
  background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);   -webkit-background-clip: text;
  color: transparent;
  
}

.rainbow-text-div{
  border-style: groove;
  border-color: rgb(53, 4, 230);
  box-shadow: 10px 10px 5px grey;
    
}


