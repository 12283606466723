.App-logo2 {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo2 {
      animation: App-logo-spin2 infinite 20s linear;
    }
  }

  @keyframes App-logo-spin2 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(11500deg);
    }
  }